
import React, { useEffect } from 'react';

function loadScripts(scripts, callback) {
  const promises = scripts.map(script => {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = script;
      scriptElement.async = true;
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      document.body.appendChild(scriptElement);
    });
  });

  Promise.all(promises).then(callback).catch(err => {
    console.error('Error loading scripts:', err);
  });
}

function Servicesdigital() {

  useEffect(() => {
    const scripts = [
      'assets/vendor/bootstrap5/js/bootstrap.min.js',
      'assets/vendor/jquery/jquery-3.6.0.min.js',
      'assets/vendor/aos/aos.js',
      'assets/js/main.js'
    ];

    loadScripts(scripts, () => {
      console.log('All scripts loaded successfully');
    });

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scripts.forEach(script => {
        const scriptElement = document.querySelector(`script[src="${script}"]`);
        if (scriptElement && scriptElement.parentNode) {
          scriptElement.parentNode.removeChild(scriptElement);
        }
      });
    };
  }, []);

  return (
    <>

<div className="s-soft">
    <a
      href="https://www.facebook.com/thandoradmskkl"
      className="s-item facebook"
    >
      <span className="fa fa-facebook" />
    </a>
    <a href="" className="s-item twitter">
      <span className="fa fa-twitter" />
    </a>
    <a
      href="https://www.instagram.com/thandoradigitalmarketing/"
      className="s-item instagram"
    >
      <span className="fa fa-instagram" />
    </a>
    <a href="" className="s-item pinterest">
      <span className="fa fa-facebook-messenger" />
    </a>
  </div>
  <section className="productbanner-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 order-lg-1 order-2 d-inline-flex flex-column justify-content-center align-self-center">
          <h1>Social Media Marketing</h1>
          <p>
            Leading DIGITAL MARKETING Company in Karaikal, Puducherry, India is
            a Registered Organization born on 2016. We are one of the Best
            Digital marketing Company in Puducherry, having a young and
            dedicated team committed to the permit of excellence.{" "}
          </p>
          <div className="productbanner-btnlist">
            <div className="text-center text-lg-start">
              <a
                href="#"
                className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
              >
                <span>Talk to our Expert</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-7 order-lg-2 order-1">
          <div className="productbanner-imgwrap pbcs nobg">
            <ul className="indt-listicon">
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon1.svg"
                  alt=""
                />
                <span>Facebook Marketing</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon2.svg"
                  alt=""
                />
                <span>Instagram Marketing</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon3.svg"
                  alt=""
                />
                <span>E-Mail Marketing</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon4.svg"
                  alt=""
                />
                <span>Whatsapp Marketing</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon5.svg"
                  alt=""
                />
                <span>Youtube Promotions</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon6.svg"
                  alt=""
                />
                <span>SEO</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon6.svg"
                  alt=""
                />
                <span>PPC Management</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon6.svg"
                  alt=""
                />
                <span>Quality Poster Designs</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon6.svg"
                  alt=""
                />
                <span>Status Video Animation Ads</span>
              </li>
            </ul>
            <img
              src="assets/img/custom-images/thandora-social-inner.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="">
    <div className="container">
      <div className="industries-entry">
        <div className="wrapper">
          <div className="tab-wrapper">
            <ul className="tabs">
              <li className="tab-link active" data-tab={1}>
                Facebook Marketing
              </li>
              <li className="tab-link" data-tab={2}>
                Instagram Marketing
              </li>
              <li className="tab-link" data-tab={3}>
                E-Mail Marketing
              </li>
              <li className="tab-link" data-tab={4}>
                Whatsapp Marketing
              </li>
            </ul>
          </div>
          <div className="content-wrapper">
            <div id="tab-1" className="tab-content active">
              2.7 billion people use Facebook every month to connect with
              friends and family and to discover things that matter. Find new
              customers and build lasting relationships with them. No two
              businesses are alike. That's why Facebook is built to help your
              business meet its specific goals. Facebook ad formats and Pages
              are designed to capture attention and prompt action. They offer
              creative flexibility, work across devices and are built to help
              you reach your goals.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-2" className="tab-content">
              Instagram's primary advantage over other social media platforms is
              its visual nature. If you have a business that benefits from the
              design of your product or if you have a service that has a visibly
              noticeable end result, Instagram is the best platform to showcase
              that content. Instagram stories is a way to get more direct with
              your audience, and, thanks to updates such as being able to
              mention accounts, add stickers, and shoppable tags, it's also
              become a great way to promote products. Not only that, it allows
              brands to interact one-on-one with their audience.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-3" className="tab-content">
              More importantly, email marketing allows you to build
              relationships with leads, customers and past customers. It's your
              opportunity to speak directly to them in their inbox, at a time
              that is convenient for them. Coupled with the right messaging,
              email can become one of your most impactful marketing channels.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-4" className="tab-content">
              WhatsApp Business enables businesses to deliver faster customer
              service. When customers opt-in, businesses can directly contact
              users on their phones. This is significant when considering that
              56% of people would rather contact customer services via messaging
              rather than over the phone. WhatsApp Business allows companies to
              safely and securely message their customers directly within the
              WhatsApp messaging platform. One advantage over SMS is that
              WhatsApp is tied directly to a single phone number and provides a
              branded business profile rather than a string of digits.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="">
    <div className="container">
      <div className="industries-entry">
        <div className="wrapper">
          <div className="tab-wrapper">
            <ul className="tabs">
              <li className="tab-link active" data-tab={5}>
                Youtube Promotions
              </li>
              <li className="tab-link" data-tab={6}>
                SEO
              </li>
              <li className="tab-link" data-tab={7}>
                PPC Management
              </li>
              <li className="tab-link" data-tab={8}>
                Quality Poster Designs
              </li>
              <li className="tab-link" data-tab={9}>
                Status Video Animation Ads
              </li>
            </ul>
          </div>
          <div className="content-wrapper">
            <div id="tab-5" className="tab-content active">
              Not only is your audience on YouTube, but as the internet's second
              largest search engine, YouTube can help improve your SEO and
              overall brand presence. YouTube allows marketers to present unique
              content that's easy for viewers to consume and share. YouTube
              marketing can be an intimidating tool for brands. YouTube is a
              critical marketing tool for practically any company in any
              industry. ... For business, online video marketing presents a
              unique opportunity. Used efficiently, video marketing can help to
              build trust and establish an organization as having authority.
              Moreover, it makes it easier to connect and engage customers.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-6" className="tab-content">
              SEO is important because it keeps the search results fair. ... The
              higher you rank in results pages, the more clicks and traffic your
              site will generate. SEO also improves user experience, making it
              more likely for customers to become repeat buyers. And SEO is
              cost-effective. When searching for a service or product online,
              users are more likely to choose one of the top five suggestions
              that the search engine shows them. SEO helps you rank higher in
              search results and garner more visibility online, making potential
              customers more likely to click over to your site and convert.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-7" className="tab-content">
              Not only is your audience on YouTube, but as the internet's second
              largest search engine, YouTube can help improve your SEO and
              overall brand presence. YouTube allows marketers to present unique
              content that's easy for viewers to consume and share. YouTube
              marketing can be an intimidating tool for brands. YouTube is a
              critical marketing tool for practically any company in any
              industry. ... For business, online video marketing presents a
              unique opportunity. Used efficiently, video marketing can help to
              build trust and establish an organization as having authority.
              Moreover, it makes it easier to connect and engage customers.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-8" className="tab-content">
              Marketing posters can reach new buyers or promote your company's
              new products and services. This is a smart way to get additional
              exposure for your company's brand. Posters are very beneficial
              because you can put them in many different places.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-9" className="tab-content">
              Using videos for promoting and telling people about your product
              or service helps increase engagement on your digital and social
              channels, educates your audience, and allows you to reach them
              with a new medium. While convenient and efficient for the
              consumer, video marketing provides marketers with an attractive,
              versatile, and extremely shareable medium to reach their
              audiences. In fact, Diode Digital recently found that online video
              is a 600% more effective marketing tool than print and direct mail
              combined.
              <div className="post-section">
                <div className="row">
                  <div className="">
                    <div className="pce-section">
                      <div className="container">
                        <div className="row gx-5 pce-mainlist">
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-1.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-1</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-right">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-2.png"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-2</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4" data-aos="fade-left">
                            <div className="pce-mlimg">
                              <img
                                src="assets/img/custom-images/post-3.jpg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="pce-mltitle">facebook post-3</p>
                              <p>
                                That's why Facebook is built to help your
                                business meet its specific goals. Facebook ad
                                formats and Pages are designed to capture
                                attention and prompt action. They offer creative
                                flexibility, work across devices and are built
                                to help you reach your goals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>


  );
}

export default  Servicesdigital;
