import React, { useEffect } from 'react';

function loadScripts(scripts, callback) {
  const promises = scripts.map(script => {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = script;
      scriptElement.async = true;
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      document.body.appendChild(scriptElement);
    });
  });

  Promise.all(promises).then(callback).catch(err => {
    console.error('Error loading scripts:', err);
  });
}

function Home() {
  useEffect(() => {
    const scripts = [
      'assets/vendor/bootstrap5/js/bootstrap.min.js',
      'assets/vendor/jquery/jquery-3.6.0.min.js',
      'assets/vendor/aos/aos.js',
      'assets/js/main.js'
    ];

    loadScripts(scripts, () => {
      console.log('All scripts loaded successfully');
    });

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scripts.forEach(script => {
        const scriptElement = document.querySelector(`script[src="${script}"]`);
        if (scriptElement && scriptElement.parentNode) {
          scriptElement.parentNode.removeChild(scriptElement);
        }
      });
    };
  }, []);

  

  return (
    <>
  <div className="s-soft">
    <a
      href="https://www.facebook.com/thandoradmskkl"
      className="s-item facebook"
    >
      <span className="fa fa-facebook" />
    </a>
    <a href="" className="s-item twitter">
      <span className="fa fa-twitter" />
    </a>
    <a
      href="https://www.instagram.com/thandoradigitalmarketing/"
      className="s-item instagram"
    >
      <span className="fa fa-instagram" />
    </a>
    <a href="" className="s-item pinterest">
      <span className="fa fa-facebook-messenger" />
    </a>
  </div>
  <section
    id="hero"
    className="hero carousel carousel-dark slide vertical"
    data-bs-ride="carousel"
  >
    <div className="container">
      <div
        className="bg-hero2 bgitem1 active"
        style={{
          background: 'url("assets/img/custom-images/bghero.jpg") no-repeat'
        }}
      />
    </div>
  </section>
  <section className="homewidget-section" id="aboutus">
    <div className="container">
      <h1 data-aos="zoom-in-up" data-aos-offset={150} className="text-center">
        About Us
      </h1>
      <div className="row gx-5">
        <div className="col-lg-5">
          <div className="homewidget-bg">
            <span data-aos="fade-down">
              <img src="assets/img/custom-images/thandora.png" alt="" />
            </span>
          </div>
        </div>
        <div
          className="col-lg-7 d-flex flex-column justify-content-center align-self-center"
          data-aos="fade-left"
        >
          <h1>THANDORA DIGITAL MARKETING SERVICES</h1>
          <p>
            Leading DIGITAL MARKETING Company in Karaikal, Puducherry, India is
            a Registered Organization born on 2016. We are one of the Best
            Digital marketing Company in Puducherry, having a young and
            dedicated team committed to the permit of excellence. We provide a
            quality Websites, Posters, FM ads, Social media marketing, Employee
            Trainings from our panel of website designers, graphic artist,
            content writers, programmers, digital marketing experts &amp;
            Certified Trainers. We offer customized &amp; innovative solutions
            to suit every client’s budget.
          </p>
          <div className="widget-btnlist">
            <div className="text-center text-lg-start">
              <a
                href="/about"
                rel="noopener"
                aria-label=""
                className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
              >
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="homeproduct-section social-section">
    <div className="container">
      <h1 data-aos="zoom-in-up" data-aos-offset={150}>
        OUR SERVICES
      </h1>
      <p data-aos="zoom-in-up" data-aos-offset={150} />
      <div className="row qproductlist " id="services-1">
        <h1 className="down-bottom" data-aos="zoom-in-up" data-aos-offset={150}>
          Social Media Marketing
        </h1>
        <div
          className="col-lg-5 d-flex flex-column justify-content-center align-self-center layer-1-position order-2 order-lg-1 "
          data-aos="fade-up"
          data-aos-offset={400}
        >
          <div className="qproduct-description">
            <h3>Our Services are</h3>
            <ul>
              <li>Facebook Marketing</li>
              <li>Instagram Marketing</li>
              <li>E-Mail Marketing</li>
              <li>Whatsapp Marketing</li>
              <li>Youtube Promotions</li>
              <li>SEO (Search Engine Optimization)</li>
              <li>PPC Management (Pay Per Click)</li>
              <li>Quality Poster Designs</li>
              <li>Status Video Animation Ads</li>
            </ul>
          </div>
        </div>
        <div
          className="col-lg-7 order-1 order-lg-2"
          data-aos="fade-right"
          data-aos-offset={400}
        >
          <div className="qproduct-img">
            <img src="assets/img/custom-images/thandora-social.png" alt="" />
          </div>
        </div>
      </div>
      <div className="line-top">
        <hr />
      </div>
      <div className="pce-section">
        <div
          className="row pce-listing"
          data-aos="zoom-in-down"
          data-aos-offset={350}
        >
          <div className="col-lg-12">
            <ul>
              <li>
                <div>
                  <img src="assets/img/custom-images/facebook.png" alt="" />
                </div>
                <div>
                  <p className="pce-title">Facebook Marketing</p>
                  <p>
                    2.7 billion people use Facebook every month to connect with
                    friends and family and to discover things that matter. Find
                    new customers and build lasting relationships with them. No
                    two businesses are alike. That's why Facebook is built to
                    help your business meet its specific goals. Facebook ad
                    formats and Pages are designed to capture attention and
                    prompt action. They offer creative flexibility, work across
                    devices and are built to help you reach your goals.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <ul>
              <li>
                <div>
                  <img src="assets/img/custom-images/instagram.png" alt="" />
                </div>
                <div>
                  <p className="pce-title">Instagram Marketing</p>
                  <p>
                    Instagram's primary advantage over other social media
                    platforms is its visual nature. If you have a business that
                    benefits from the design of your product or if you have a
                    service that has a visibly noticeable end result, Instagram
                    is the best platform to showcase that content. Instagram
                    stories is a way to get more direct with your audience, and,
                    thanks to updates such as being able to mention accounts,
                    add stickers, and shoppable tags, it's also become a great
                    way to promote products. Not only that, it allows brands to
                    interact one-on-one with their audience.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <ul>
              <li>
                <div>
                  <img src="assets/img/custom-images/email.png" alt="" />
                </div>
                <div>
                  <p className="pce-title">E-Mail Marketing</p>
                  <p>
                    More importantly, email marketing allows you to build
                    relationships with leads, customers and past customers. It's
                    your opportunity to speak directly to them in their inbox,
                    at a time that is convenient for them. Coupled with the
                    right messaging, email can become one of your most impactful
                    marketing channels.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center">
          <a
            href="/services-digital"
            rel="noopener"
            aria-label=""
            className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
          >
            <span>View More</span>
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="homeproduct-section fm-radio">
    <div className="container">
      <div className="row qproductlist alter" id="services-2">
        <h1 data-aos="zoom-in-up" data-aos-offset={150}>
          FM Advertisements
        </h1>
        <div
          className="col-lg-5 d-flex flex-column justify-content-center align-self-center order-2 order-lg-1 layer-2-position"
          data-aos="fade-up"
          data-aos-offset={100}
        >
          <div className="qproduct-description">
            <h3>Our Services are</h3>
            <ul>
              <li>Male &amp; Female Hd Voice Cuts</li>
              <li>A-Z Voice Ads In All India Radio</li>
              <li>Sponsorship Programs</li>
              <li>Daybranding Programs</li>
              <li>Time Check Ads</li>
              <li>Live Shows From Shop</li>
            </ul>
          </div>
        </div>
        <div
          className="col-lg-7 order-1 order-lg-2"
          data-aos="fade-right"
          data-aos-offset={400}
        >
          <div className="qproduct-img">
            <img src="assets/img/custom-images/thandora-fm.png" alt="" />
          </div>
        </div>
      </div>
      <div className="line-top">
        <hr />
      </div>
      <div className="pce-section">
        <div className="text-center">
          <a
            href="/services-fm"
            rel="noopener"
            aria-label=""
            className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
          >
            <span>View More</span>
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="homeproduct-section website-dev">
    <div className="container">
      <div className="row qproductlist alter " id="services-3">
        <h1 data-aos="zoom-in-up" data-aos-offset={150}>
          Website Development
        </h1>
        <div
          className="col-lg-5 d-flex flex-column justify-content-center align-self-center order-2 order-lg-1 layer-4-position"
          data-aos="fade-up"
          data-aos-offset={100}
        >
          <div className="qproduct-description">
            <h1 />
            <h3>Our Services are</h3>
            <ul>
              <li>Statics Website Development</li>
              <li>Dynamic Website Development</li>
              <li>E-commerce Website Development</li>
              <li>WordPress Website Development</li>
              <li>Redesign Website Development</li>
            </ul>
          </div>
        </div>
        <div
          className="col-lg-7 order-1 order-lg-2"
          data-aos="fade-right"
          data-aos-offset={400}
        >
          <div className="qproduct-img">
            <img src="assets/img/custom-images/thandora-website.png" alt="" />
          </div>
        </div>
      </div>
      <div className="line-top">
        <hr />
      </div>
      <div className="pce-section">
        <div
          className="row pce-listing"
          data-aos="zoom-in-down"
          data-aos-offset={350}
        >
          <div className="col-lg-12">
            <ul>
              <li>
                <div>
                  <img
                    src="assets/img/custom-images/basic-website.png"
                    alt=""
                  />
                </div>
                <div>
                  <p className="pce-title">Statics Website Development</p>
                  <p>
                    We develop websites for business with minimum budget and
                    industry specialized to have a better experience. For Basics
                    website design and development, we include value addition
                    and culture and user friendly for each web design.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <ul>
              <li>
                <div>
                  <img
                    src="assets/img/custom-images/custom-website.png"
                    alt=""
                  />
                </div>
                <div>
                  <p className="pce-title">Dynamic Website Development</p>
                  <p>
                    Dynamic Website Design is little advanced for business
                    promotions We will give support to set up &amp; integrate
                    your Social Media accounts and personalized email ID
                    acccounts.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <ul>
              <li>
                <div>
                  <img src="assets/img/custom-images/ecommerce.png" alt="" />
                </div>
                <div>
                  <p className="pce-title">E-commerce Website Development</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aenean enim enim, venenatis vel justo ut, venenatis cursus
                    libero. Cras mi ligula, malesuada ac dignissim quis, porta
                    ut elit.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center">
          <a
            href="/services-website"
            rel="noopener"
            aria-label=""
            className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
          >
            <span>View More</span>
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="homeproduct-section training-dev">
    <div className="container">
      <div className="row qproductlist alter " id="services-4">
        <h1 data-aos="zoom-in-up" data-aos-offset={150}>
          Soft Skills Training
        </h1>
        <div
          className="col-lg-5 d-flex flex-column justify-content-center align-self-center order-2 order-lg-1 layer-4-position"
          data-aos="fade-up"
          data-aos-offset={100}
        >
          <div className="qproduct-description">
            <h3>Our Services </h3>
            <ul>
              <li>Motivational Talks</li>
              <li>Audio / Music Based Activities</li>
              <li>OBT (Outbound Training)</li>
              <li>Group Games</li>
              <li>Powerpoint Aided Presentations</li>
              <li>Role Play / Group Discussions</li>
              <li>Spoken English Training</li>
              <li>Effective Communication Class</li>
              <li>Interpersonal Relationship &amp; Problem Solving Skills</li>
              <li>Life Changing Sessions For Students</li>
              <li>TTT (TRAIN THE TEACHERS) 8 Hrs Training</li>
              <li>Stress Management For Employees</li>
              <li>Family Meet Games &amp; Activities</li>
              <li>RYLA (ROTARY YOUTH LEADERSHIP AWARDS )</li>
              <li>Art Of Parenting</li>
            </ul>
          </div>
        </div>
        <div
          className="col-lg-7 order-1 order-lg-2"
          data-aos="fade-right"
          data-aos-offset={400}
        >
          <div className="qproduct-img">
            <img
              src="assets/img/custom-images/thandora-softskills.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <a
          href="/services-softskills"
          rel="noopener"
          aria-label=""
          className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
        >
          <span>View More</span>
        </a>
      </div>
    </div>
  </section>
</>

  );
}

export default Home;
