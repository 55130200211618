import React from 'react';

function AboutUs() {
  return (
   <>


<div className="s-soft">
    <a
      href="https://www.facebook.com/thandoradmskkl"
      className="s-item facebook"
    >
      <span className="fa fa-facebook" />
    </a>
    <a href="" className="s-item twitter">
      <span className="fa fa-twitter" />
    </a>
    <a
      href="https://www.instagram.com/thandoradigitalmarketing/"
      className="s-item instagram"
    >
      <span className="fa fa-instagram" />
    </a>
    <a href="" className="s-item pinterest">
      <span className="fa fa-facebook-messenger" />
    </a>
  </div>
  <section className="productbanner-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 order-lg-1 order-2 d-inline-flex flex-column justify-content-center align-self-center">
          <h1>THANDORA DIGITAL MARKETING SERVICES </h1>
          <p>
            Leading DIGITAL MARKETING Company in Karaikal, Puducherry, India is
            a Registered Organization born on 2016. We are one of the Best
            Digital marketing Company in Puducherry, having a young and
            dedicated team committed to the permit of excellence.{" "}
          </p>
          <div className="productbanner-btnlist">
            <div className="text-center text-lg-start">
              <a
                href="#"
                className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
              >
                <span>Talk to our Expert</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-7 order-lg-2 order-1">
          <div className="productbanner-imgwrap pbcs nobg">
            <ul className="indt-listicon">
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon1.svg"
                  alt=""
                />
                <span>Social Media Marketing</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon2.svg"
                  alt=""
                />
                <span>Fm Advertisements</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon3.svg"
                  alt=""
                />
                <span>Website Development</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon4.svg"
                  alt=""
                />
                <span>Soft Skills Training</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon5.svg"
                  alt=""
                />
                <span>Training</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon6.svg"
                  alt=""
                />
                <span>Facebook Marketing</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon6.svg"
                  alt=""
                />
                <span>Instagram Marketing</span>
              </li>
              <li>
                <img
                  src="assets/img/svg/industries/indt-bannericon6.svg"
                  alt=""
                />
                <span>E-Mail Marketing</span>
              </li>
            </ul>
            <img
              src="assets/img/custom-images/thandora-social-inner.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="industries-section">
    <div className="container">
      <div className="industries-entry">
        <p>
          We provide a quality Websites, Posters, FM ads, Social media
          marketing, Employee Trainings from our panel of website designers,
          graphic artist, content writers, programmers, digital marketing
          experts &amp; Certified Trainers. We offer customized &amp; innovative
          solutions to suit every client’s budget.
        </p>
      </div>
      <div className="industries-widget">
        <h1>Thandora for Social Media Marketing</h1>
        <p className="indt-subtitle">
          Make doing business with you faster, simpler, and easier
        </p>
        <div className="row industries-listing gx-5">
          <div className="col-lg-6">
            <div className="indt-img">
              <img src="assets/img/custom-images/socail-marketing.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                <div>
                  <p className="pce-title">Facebook Marketing</p>
                  <p>
                    2.7 billion people use Facebook every month to connect with
                    friends and family and to discover things that matter. Find
                    new customers and build lasting relationships with them. No
                    two businesses are alike.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">Instagram Marketing</p>
                  <p>
                    Instagram's primary advantage over other social media
                    platforms is its visual nature. If you have a business that
                    benefits from the design of your product or if you have a
                    service that has a visibly noticeable end result, Instagram
                    is the best platform to showcase that content.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">E-Mail Marketing</p>
                  <p>
                    More importantly, email marketing allows you to build
                    relationships with leads, customers and past customers. It's
                    your opportunity to speak directly to them in their inbox,
                    at a time that is convenient for them.
                  </p>
                </div>
              </li>
            </ul>
            <div className="widget-btnlist">
              <div className="text-center text-lg-start">
                <a
                  href="about.html"
                  rel="noopener"
                  aria-label=""
                  className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="industries-widget">
        <h1>Thandora for FM Advertisements</h1>
        <p className="indt-subtitle">
          Make doing business with you faster, simpler, and easier
        </p>
        <div className="row industries-listing gx-5">
          <div className="col-lg-6">
            <div className="indt-img">
              <img src="assets/img/custom-images/fm.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                <div>
                  <p className="pce-title">Male &amp; Female Hd Voice Cuts</p>
                  <p>
                    Royalty free audio track is a great option for any project
                    that requires human sounds and other aspects such as a
                    female, voice and vocal.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">A-Z Voice Ads In All India Radio</p>
                  <p>
                    Voice Ads are an advertising solution from Business that
                    helps you reach audiences across All India Radio.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">Sponsorship Programs</p>
                  <p>
                    As almost everyone with a successful career can attest,
                    having a mentor or sponsor can make a huge difference at
                    work.
                  </p>
                </div>
              </li>
            </ul>
            <div className="widget-btnlist">
              <div className="text-center text-lg-start">
                <a
                  href="about.html"
                  rel="noopener"
                  aria-label=""
                  className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="industries-widget">
        <h1>Thandora for Website Development</h1>
        <p className="indt-subtitle">
          Make doing business with you faster, simpler, and easier
        </p>
        <div className="row industries-listing gx-5">
          <div className="col-lg-6">
            <div className="indt-img">
              <img src="assets/img/custom-images/website.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                <div>
                  <p className="pce-title">Statics Website Development</p>
                  <p>
                    We develop websites for business with minimum budget and
                    industry specialized to have a better experience. For Basics
                    website design and development, we include value addition
                    and culture and user friendly for each web design.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">Dynamic Website Development</p>
                  <p>
                    Dynamic Website Design is little advanced for business
                    promotions We will give support to set up &amp; integrate
                    your Social Media accounts and personalized email ID
                    acccounts.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">E-commerce Website Development</p>
                  <p>
                    Students and educators can easily discuss study materials in
                    group video calls equipped with screen sharing and
                    whiteboards to ensure difficult concepts are communicated
                    effectively.
                  </p>
                </div>
              </li>
            </ul>
            <div className="widget-btnlist">
              <div className="text-center text-lg-start">
                <a
                  href="about.html"
                  rel="noopener"
                  aria-label=""
                  className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="industries-widget">
        <h1>Thandora for Soft Skills</h1>
        <p className="indt-subtitle">
          Make doing business with you faster, simpler, and easier
        </p>
        <div className="row industries-listing gx-5">
          <div className="col-lg-6">
            <div className="indt-img">
              <img src="assets/img/custom-images/soft.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                <div>
                  <p className="pce-title">Motivational Talks</p>
                  <p>
                    Motivational talks appeal to people's emotions as it makes
                    them feel good about a story of a person or an incident or a
                    philosophy that they can imbibe in their lives.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">Audio / Music Based Activities</p>
                  <p>
                    These activities will help engage your in active music
                    listening as well as teach them about the emotional,
                    musical, and sequential characteristics of music.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="pce-title">OBT (Outbound Training)</p>
                  <p>
                    (OBT)/ Outbound Learning is a training method for enhancing
                    individual, team and organizational performance through
                    experiential learning methodology where participants are
                    involved into outbound training activities.
                  </p>
                </div>
              </li>
            </ul>
            <div className="widget-btnlist">
              <div className="text-center text-lg-start">
                <a
                  href="about.html"
                  rel="noopener"
                  aria-label=""
                  className="qbtn qbtn-orange d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  );
}

export default AboutUs;
