import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/About';
import ServicesDigital from './components/Servicesdigital';
import Serviceswebsite from './components/Serviceswebsite';
import Servicessoftskills from './components/Servicessoftskills';
import Servicesfm from './components/Servicesfm';
import './App.css';

function App() {
  return (
    <Router>
      <header id="header" className="header header-light fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <Link to="/" className="qlogo d-flex align-items-center" title="Thandora" rel="noopener noreferrer">
            <img src="assets/img/logo.png" className="qlogo-primary" alt="" />
            <img src="assets/img/logo.png" className="qlogo-light" alt="" />
            <h3>Thandora DMS</h3>
          </Link>
          <nav id="navbar" className="navbar">
            <input type="checkbox" className="chkboxqmera" />
            <div className="overlay" />
            <a href="#" className="mobile-nav-toggle">
              <span />
              <span />
              <span />
              <span />
            </a>
            <ul>
              <div className="smalllogo">
                <Link to="/home" className="qlogo d-flex align-items-center" title="Thandora" rel="noopener noreferrer">
                  <img src="assets/img/logo.jpg" alt="" />
                  <h3>Thandora DMS</h3>
                </Link>
              </div>
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/training">Training</Link>
              </li>
              <li className="dropdown">
                <Link to="/services-digital">
                  <span>Services</span> <i className="qmera-ddicon" />
                </Link>
                <ul>
                  <li>
                    <Link to="/services-digital">Social Media Marketing</Link>
                  </li>
                  <li>
                    <Link to="/services-fm">Fm Advertisements</Link>
                  </li>
                  <li>
                    <Link to="/services-website">Website Development</Link>
                  </li>
                  <li>
                    <Link to="/services-softskills">Soft Skills Training</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      
      <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/home" element={<Home />} />
  <Route path="/about" element={<AboutUs />} />
  <Route path="/services-digital" element={<ServicesDigital />} />
  <Route path="/services-fm" element={<Servicesfm />} />
  <Route path="/services-softskills" element={<Servicessoftskills />} />
  <Route path="/services-website" element={<Serviceswebsite />} />
</Routes>

      <a href="https://wa.me/919884433933" target="_blank" class="whatsapp-icon"><i class="fab fa-whatsapp fa-2x fa-fw"></i></a>

      <footer>
  <div className="container container-1">
    <div className="footer-content">
      <ul className="footer-menu-links">
        <li>
          <Link to="/Home">Home</Link>
        </li>
        <li>
        <Link to="/about">About Us</Link>
        </li>
        <li>
          <a href="#">Training</a>
        </li>
        <li>
          <a href="#">Our Services</a>
          <div className="mt-3">
          <Link to="/services-digital">Social Media Marketing</Link>
            <br />
            <Link to="/services-fm">Fm Advertisements</Link>
            <br />
            <Link to="/services-website">Website Development</Link>
            <br />
            <Link to="/services-softskills">Soft Skills Training</Link>
          </div>
        </li>
        <li>
          <a href="#">Our Works</a>
        </li>
        <li>
          <a href="#">Company</a>
        </li>
      </ul>
      <p className="copyright-txt">
        © 2024 Thandora Digital Marketing Services. All rights reserved{" "}
      </p>
    </div>
  </div>
</footer>

      
    </Router>

    
  );
}

export default App;
